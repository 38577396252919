.recovery-step-three-form {
  margin: 32px 0;
}
.recovery-step-three-form__sub {
  text-align: left;
  margin-bottom: 24px;
  max-width: 300px;
}
.recovery-step-three-form__row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 32px;
}
.recovery-step-three-form__code {
  flex: 1 1 auto;
}
.recovery-step-three-form__timer {
  flex: 0 0 32px;
  margin-left: 16px;
}
.recovery-step-three-form__label span {
  padding-left: 16px;
  color: #000;
    }
.recovery-step-three-form__resend {
  margin-top: -8px;
  font-size: 14px;
  text-align: right;
}
.recovery-step-three-form__footer {
  margin-top: 64px;
}
