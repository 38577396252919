.input-switch-component {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 4px 0;
  line-height: 0;
  cursor: pointer;
  user-select: none;
}
.input-switch-component__input {
  width: 32px;
  height: 20px;
  border-radius: 22px;
  background-color: #cccccc;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: background-color 0.1s ease;
  border: none;
}
.input-switch-component__checked {
  position: absolute;
  left: 3px;
  top: 6px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
  transition: transform 0.1s ease;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
}
.input-switch-component_theme_blue {}
.input-switch-component_theme_blue .input-switch-component__input {
  background-color: #4022e2;
}
.input-switch-component_checked {}
.input-switch-component_checked .input-switch-component__checked {
  transform: translateX(10px);
}
.input-switch-component_checked .input-switch-component__input {
  background-color: #4022e2;
}
.input-switch-component__label {
  margin-left: 8px;
}

