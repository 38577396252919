@keyframes timer-progress {
  from {
    stroke-dasharray: 0, 100;
  }

  to {
    stroke-dasharray: 100, 100;
  }
}
.timer-component {
  position: relative;
}
.timer-component__progress_animated {
  animation: timer-progress 60s linear;
  }
.timer-component__time {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 1;
  }
.timer-component__control {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform 0.2s ease;
  }
.timer-component__control:hover {
  transform: rotate(180deg);
    }
