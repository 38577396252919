.paragraph {
  color: inherit;
  line-height: 1;
}
.paragraph_size_p1 {
  font-size: 40px;
  font-weight: bold;
}
@media (max-width: 800px) {
  .paragraph_size_p1 {
  font-size: 28px;
  }
    }
.paragraph_size_p2 {
  font-size: 28px;
  font-weight: 700;
}
@media (max-width: 800px) {
  .paragraph_size_p2 {
  font-size: 20px;
  }
    }
.paragraph_size_p3 {
  font-size: 14px;
  font-weight: 700;
}
.paragraph_size_p4 {
  font-size: 14px;
  font-weight: normal;
}
