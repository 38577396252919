.company-name-energy-block {
  text-align: center;
}
.company-name-energy-block__header {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 64px;
  padding: 0 64px;
}
.company-name-energy-block__fire {
  position: absolute;
  right: 0;
  height: 64px;
  margin-left: 24px;
}
