.recovery-form {
  margin: 32px 0;
}
.recovery-form__sub {
  text-align: center;
  margin-bottom: 24px;
}
.recovery-form__row {
  margin-bottom: 48px;
}
.recovery-form__row_radio {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.recovery-form__row_help {
  margin-bottom: 64px;
}
.recovery-form__footer {
  margin-top: 64px;
}
.recovery-form__help b {
  color: #000;
  font-weight: normal;
    }
