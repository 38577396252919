.period-component {
  position: relative;
  display: inline-block;
}

  .period-component .CalendarDay__selected_start {
  border-radius: 50% 0 0 50%;
  }

  .period-component .CalendarDay__selected_end {
  border-radius: 0 50% 50% 0;
  }

  .period-component .CalendarDay__hovered_span:hover {
  border-radius: 0 50% 50% 0;
  }
