.table-component {
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  text-align: left;
  background-color: #fff;
}
.table-component__column {
  //border-bottom: #efefef 1px solid;
}
.table-component__footer-column {
  //border-top: #efefef 1px solid;
  text-align: right;
}
.table-component__row {
  cursor: default;
}
.table-component__row_pointer {
  cursor: pointer;
}
.table-component__column-inner {
  border-bottom: #efefef 1px solid;
  background-color: #f9f9f9;
}
.table-component__row:last-child td {
  border-bottom: none;
}
.table-component__row-inner:last-child td {
  border-bottom: none;
}
.table-component_size_middle {}
.table-component_size_middle .table-component__th {
  padding: 16px;
  font-size: 12px;
}
.table-component_size_middle .table-component__column {
  padding: 16px;
}
.table-component_size_middle .table-component__column-inner {
  padding: 16px;
}
.table-component_size_middle .table-component__footer-column {
  padding: 16px;
}
.table-component_size_small {}
.table-component_size_small .table-component__th {
  padding: 8px;
  font-size: 12px;
  font-weight: normal;
}
.table-component_size_small .table-component__column {
  padding: 12px 8px;
  font-size: 12px;
  line-height: 1;
}
.table-component_theme_default {
  box-shadow: 0 0 0 1px #DFE0EB;
  border-radius: 8px;
}
.table-component_theme_default .table-component__th {
  color: #BDBFCE;
  border-bottom: 1px solid #DFE0EB;
}
.table-component_theme_default .table-component__th:first-child {
  border-radius: 8px 0 0 0;
      }
.table-component_theme_default .table-component__th:last-child {
  border-radius: 0 8px 0 0;
      }
.table-component_theme_default .table-component__placeholder {
  color: #BDBFCE;
}
.table-component_theme_inset {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #DFE0EB;
}
.table-component_theme_inset .table-component__th {
  color: #BDBFCE;
  border-bottom: 1px solid #DFE0EB;
}
.table-component_theme_inset .table-component__placeholder {
  color: #BDBFCE;
}
.table-component_theme_plain {
  border: none;
  background-color: inherit;
}
.table-component_theme_plain .table-component__th {
  background-color: transparent;
  color: #BDBFCE;
}
.table-component_theme_plain .table-component__th:first-child {
  padding-left: 0;
      }
.table-component_theme_plain .table-component__th:last-child {
  padding-right: 0;
      }
.table-component_theme_plain .table-component__column:first-child {
  padding-left: 0;
      }
.table-component_theme_plain .table-component__column:last-child {
  padding-right: 0;
      }
.table-component_hover {}
.table-component_hover .table-component__row:hover {
  background-color: #f9f9f9;
}
.table-component_pointer {}
.table-component_pointer .table-component__row {
  cursor: pointer;
}
.table-component_scroll tbody {
  display: block;
  overflow-y: auto;
  padding-right: 12px;
    }
.table-component_scroll tbody::-webkit-scrollbar {
  width: 4px;
      }
.table-component_scroll tbody::-webkit-scrollbar-thumb {
  background-color: #3b25dc;
  border-radius: 4px;
      }
.table-component_scroll tbody::-webkit-scrollbar-track {
  background-color: rgba(59, 37, 220, 0.06);
  border-radius: 4px;
      }
.table-component_scroll tbody::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
      }
.table-component_scroll tbody::-webkit-scrollbar-corner {
  background-color: transparent;
      }
.table-component_scroll thead, .table-component_scroll tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
    }
.table-component_responsive table, .table-component_responsive thead, .table-component_responsive tbody, .table-component_responsive th, .table-component_responsive td, .table-component_responsive tr {
  display: block;
    }
.table-component_responsive .table-component__th {
  display: none;
}
.table-component_responsive .table-component__column {
  padding: 16px 0;
}
.table-component_responsive .table-component__column::before {
  content: attr(data-label);
  display: block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 1;
      }
.table-component_responsive .table-component__row {
  border-bottom: #efefef 1px solid;
}
.table-component_responsive .table-component__row:last-child td {
  border-bottom: #efefef 1px solid;
}
.table-component_responsive .table-component__row-inner:last-child td {
  border-bottom: #efefef 1px solid;
}
.table-component_responsive .table-component__row:last-child {
  border-bottom: none;
}
.table-component_responsive .table-component__row:last-child td:last-child {
  border-bottom: none;
}
.table-component_header {}
.table-component_header .table-component__th:first-child {
  border-radius: 0;
      }
.table-component_header .table-component__th:last-child {
  border-radius: 0;
      }
.table-component__pagination {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.table-component__pagination-total {
  font-size: 12px;
  opacity: 0.6;
}
.table-component__pagination-controls {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.table-component__pagination-button {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #eff5fc;
  border: none;
  cursor: pointer;
  color: #00416a;
  transition: background-color 0.2s ease;
}
.table-component__pagination-button:hover {
  background-color: #dfe9ff;
    }
.table-component__pagination-button:last-child {
  margin-right: 0;
    }
.table-component__pagination-button_disabled {
  pointer-events: none;
  opacity: 0.4;
}
.table-component__placeholder {
  margin: 32px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

