.registration-finish-page {}
.registration-finish-page__title {
  max-width: 320px;
  text-align: center;
}
.registration-finish-page__img {
  display: block;
  width: 200px;
  margin: 64px auto;
}
