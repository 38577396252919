.input-select-component {
  position: relative;
  cursor: pointer;
}
.input-select-component__input {
  user-select: none;
}
.input-select-component__input > * {
  pointer-events: none;
    }
.input-select-component__input_loading {
  pointer-events: none;
}
.input-select-component__options {
  position: absolute;
  display: none;
  top: auto;
  left: 0;
  right: 0;
  margin-top: -1px;
  max-height: 300px;
  overflow-y: auto;
  list-style: none;
  border: #f5f5f5 1px solid;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  z-index: 2;
}
.input-select-component__options_open {
  display: block;
}
.input-select-component__option {
  padding: 16px;
}
.input-select-component__option:hover {
  background-color: #f5f5f5;
    }
.input-select-component__arrow {
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  transition: transform 0.2s ease;
  color: rgba(0, 0, 0, 0.4);
}
.input-select-component__arrow_open {
  transform: translateY(-50%) rotate(180deg);
}

