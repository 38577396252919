.price-chart-block {
  display: flex;
  flex-flow: row nowrap;
  margin: -16px;
}

  @media (max-width: 620px) {

  .price-chart-block {
  flex-wrap: wrap;
  }
  }

.price-chart-block__title {
  margin-bottom: 16px;
}

.price-chart-block__left {
  flex: 1 1 70%;
  padding: 16px;
}

@media (max-width: 620px) {

  .price-chart-block__left {
  flex: 1 1 auto;
  }
    }

.price-chart-block__right {
  flex: 1 1 30%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  list-style: none;
  border-left: 1px solid #DFE0EB;
}

@media (max-width: 620px) {

  .price-chart-block__right {
  border-left: none;
  border-top: 1px solid #DFE0EB;
  }
    }

.price-chart-block__month-item {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  padding: 16px;
  border-bottom: 1px solid #DFE0EB;
}

.price-chart-block__month-item:last-child {
  border-bottom: none;
    }

.price-chart-block__month-label {
  margin-bottom: 8px;
}

.price-chart-block__month-value {
  font-size: 20px;
  font-weight: 500;
}
