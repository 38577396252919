.input-password-component {
  position: relative;
}
.input-password-component__eye {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.input-password-component__eye_active {
  color: #0648b8;
}

