@keyframes show-loader {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.loader-component {
  position: absolute;
  display: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  border-radius: inherit;
  z-index: 4;
}
.loader-component_color_black {
  background-color: rgba(0, 0, 0, 0.2);
}
.loader-component_color_white {
  background-color: rgba(255, 255, 255, 0.5);
}
.loader-component_show {
  display: block;
  animation: show-loader 0.2s ease both;
}
.loader-component_fixed {
  position: fixed;
}
.loader-component__progress {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

