.login-form {}
.login-form__title {
  margin-bottom: 64px;
  text-align: center;
}
.login-form__row {
  margin-bottom: 32px;
}
.login-form__row_box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
}
.login-form__footer {
  margin-top: 64px;
}
.login-form__registration {
  margin-top: 16px;
  text-align: center;
}
