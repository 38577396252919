.input-checkbox {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  user-select: none;
  cursor: pointer;
}
.input-checkbox__input {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #d8d8d8;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.input-checkbox__input::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #0648b8;
  border-radius: 4px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0id2hpdGUiIHdpZHRoPSIxOHB4IiBoZWlnaHQ9IjE4cHgiPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNOSAxNi4xN0w0LjgzIDEybC0xLjQyIDEuNDFMOSAxOSAyMSA3bC0xLjQxLTEuNDF6Ii8+PC9zdmc+);
  background-size: 14px;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0);
  transition: all 0.1s ease;
    }
.input-checkbox__input:checked::after {
  transform: scale(1);
    }
.input-checkbox__label {
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1;
}

