.registration-step-otp-form {}
.registration-step-otp-form__sub {
  max-width: 340px;
  margin: 64px 0 32px 0;
}
.registration-step-otp-form__row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 32px;
}
.registration-step-otp-form__code {
  flex: 1 1 auto;
}
.registration-step-otp-form__timer {
  flex: 0 0 32px;
  margin-left: 16px;
}
.registration-step-otp-form__footer {
  margin-top: 64px;
}
