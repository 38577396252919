.agreement-block {}
.agreement-block__main {
  display: flex;
  flex-flow: row nowrap;
  margin: 24px 0;
}
.agreement-block__data {
  flex: 1 1 auto;
}
.agreement-block__data dt {
  margin-bottom: 8px;
  font-size: 12px;
  color: #666;
    }
.agreement-block__data dd {
  font-size: 16px;
  font-weight: bold;
    }
