.aside-block {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: space-between;
  height: inherit;
  overflow: hidden;
}
.aside-block__header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 16px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
}
@media (max-width: 1000px) {
  .aside-block__header {
  padding: 8px;
  }
    }
.aside-block__icon {
  flex: 0 0 42px;
  width: 42px;
  height: 42px;
  margin-right: 16px;
  background-color: #fff;
  background-image: url("/assets/images/fire.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px;
}
.aside-block__icon_energy {
  background-image: url("/assets/images/power.svg");
}
@media (max-width: 1000px) {
  .aside-block__header-text {
  display: none;
  }
    }
.aside-block__footer {
  position: relative;
  padding: 16px;
  color: #ffffff;
}
.aside-block__footer::before {
  content: "";
  position: absolute;
  right: 32px;
  bottom: -150px;
  width: 300px;
  height: 300px;
  background-color: rgba(50, 88, 174, 0.5);
  border-radius: 50%;
  z-index: -1;
    }
@media (max-width: 1000px) {
  .aside-block__footer {
  opacity: 0;
  }
    }
@media (max-width: 620px) {
  .aside-block__footer {
  display: none;
  }
    }
.aside-block__text {
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.6);
}
.aside-block__manager-name {
  margin-bottom: 8px;
}
.aside-block__phone {
  color: inherit;
  text-decoration: none;
}
.aside-block__mobile {
  display: none;
  position: absolute;
  right: 16px;
  top: 12px;
  color: #fff;
  font-size: 32px;
  background: none;
  border: none;
  cursor: pointer;
}
@media (max-width: 620px) {
  .aside-block__mobile {
  display: block;
  }
    }
@media (max-width: 620px) {
  .aside-block__menu {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 58px;
  padding: 16px 0;
  background-color: #234CAC;
  z-index: 2;
  }
    }
.aside-block__menu_open {
  display: block;
}
