.modal-component {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9;
}
.modal-component_auto-width {}
.modal-component_auto-width .modal-component__dialog {
  width: auto;
}
.modal-component__dialog {
  position: relative;
  width: 600px;
  padding: 24px;
  margin: 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 18px 5px #0000000c;
}
.modal-component__close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  line-height: 0;
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

