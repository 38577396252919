.recovery-step-two-form {
  margin: 32px 0;
}
.recovery-step-two-form__sub {
  text-align: left;
  margin-bottom: 24px;
  max-width: 300px;
}
.recovery-step-two-form__row {
  margin-bottom: 32px;
}
.recovery-step-two-form__label span {
  padding-left: 16px;
  color: #000;
    }
.recovery-step-two-form__footer {
  margin-top: 64px;
}
