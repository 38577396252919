.drop-down-component {
  position: relative;
  cursor: pointer;
  user-select: none;
}
.drop-down-component__label {
  line-height: 1;
}
.drop-down-component_arrow {}
.drop-down-component_arrow .drop-down-component__label {
  padding-right: 18px;
}
.drop-down-component_valign_top {}
.drop-down-component_valign_top .drop-down-component__options {
  top: -100%;
  transform: translateY(-100%);
}
.drop-down-component__options {
  position: absolute;
  display: none;
  top: auto;
  right: 0;
  margin-top: 8px;
  list-style: none;
  border: #f5f5f5 1px solid;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  text-align: left;
  z-index: 2;
}
.drop-down-component__option {
  padding: 16px;
}
.drop-down-component__option:hover {
  background-color: #f5f5f5;
    }
.drop-down-component__arrow {
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  transition: transform 0.2s ease;
  color: rgba(0, 0, 0, 0.4);
}
.drop-down-component_nowrap {}
.drop-down-component_nowrap .drop-down-component__options {
  white-space: nowrap;
  right: auto;
}
.drop-down-component_nowrap.drop-down-component_align_right {}
.drop-down-component_nowrap.drop-down-component_align_right .drop-down-component__options {
  left: auto;
  right: 0;
}
.drop-down-component_open {}
.drop-down-component_open .drop-down-component__options {
  display: block;
}
.drop-down-component_open .drop-down-component__arrow {
  transform: translateY(-50%) rotate(180deg);
}

