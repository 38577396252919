.menu-block {
  list-style: none;
}
.menu-block__link {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 42px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.menu-block__link:hover {
  background-color: rgba(159, 162, 180, 0.08);
    }
.menu-block__link::before {
  content: "";
  flex: 0 0 2px;
  height: 100%;
  margin-right: 14px;
  background-color: inherit;
    }
@media (max-width: 1000px) {
  .menu-block__link::before {
  margin-right: 16px
    }
      }
.menu-block__link_active {
  background-color: rgba(159, 162, 180, 0.08);
}
.menu-block__link_active::before {
  background-color: red;
      }
.menu-block__icon {
  margin-right: 16px;
  font-size: 20px;
}
@media (max-width: 1000px) {
  .menu-block__icon {
  margin-right: 20px;
  }
    }
.menu-block__line {
  margin: 16px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}
