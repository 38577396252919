.authorization-layout {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: inherit;
}
.authorization-layout__bg {
  position: absolute;
  right: 0;
  bottom: -250px;
  z-index: -1;
  pointer-events: none;
}
.authorization-layout__bg > div {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.7;
    }
@media (max-width: 1400px) {
  .authorization-layout__bg {
  bottom: -150px;
  }
    }
@media (max-width: 1200px) {
  .authorization-layout__bg {
  bottom: -110px;
  }
    }
@media (max-width: 1100px) {
  .authorization-layout__bg {
  bottom: 0;
  right: 0;
  }
    }
.authorization-layout__main {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow: hidden;
}
.authorization-layout__left {
  position: relative;
  flex: 1 1 50%;
  align-self: flex-start;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  margin-top: 200px;
  color: #fff;
}
@media (max-width: 1100px) {
  .authorization-layout__left {
  position: static;
  margin-top: 64px;
  }
    }
@media (max-width: 980px) {
  .authorization-layout__left {
  flex: 0;
  }
    }
@media (max-width: 520px) {
  .authorization-layout__left {
  display: none;
  }
    }
.authorization-layout__right {
  flex: 1 1 50%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100%;
  //justify-content: center;
}
@media (max-width: 1100px) {
  .authorization-layout__right {
  margin-right: 64px;
  }
    }
@media (max-width: 980px) {
  .authorization-layout__right {
  margin-right: 0;
  }
    }
@media (max-width: 520px) {
  .authorization-layout__right {
  flex-flow: column nowrap;
  }
    }
.authorization-layout__right-main {
  margin: auto;
}
.authorization-layout__right-header {
  display: flex;
  justify-content: flex-end;
  min-width: 480px;
  margin: 32px 0 -32px 0;
}
@media (max-width: 520px) {
  .authorization-layout__right-header {
  min-width: 100%;
  justify-content: center;
  }
    }
.authorization-layout__lang {
  flex: 0 0 120px;
}
.authorization-layout__logo {
  width: 180px;
  margin-bottom: 16px;
}
@media (max-width: 520px) {
  .authorization-layout__mobile {
  display: block;
  margin-top: 32px;
  text-align: center;
  }
    }
.authorization-layout__content {
  flex: 0 1 600px;
  margin: 0 64px;
}
@media (max-width: 980px) {
  .authorization-layout__content {
  display: none;
  }
    }
.authorization-layout__text {
  font-size: 38px;
  letter-spacing: 2px;
}
@media (max-width: 1100px) {
  .authorization-layout__text {
  font-size: 32px;
  }
    }
.authorization-layout__text-footer {
  margin-top: 32px;
  font-weight: 600;
}
.authorization-layout__form {
  position: relative;
  min-width: 480px;
  min-height: 400px;
  margin: 64px 0;
  padding: 64px;
  border-radius: 32px;
  border: #f5f5f5 1px solid;
  background-color: #fdfdfd;
}
@media (max-width: 520px) {
  .authorization-layout__form {
  min-width: 100%;
  background-color: #fff;
  border: none;
  padding: 32px;
  }
    }
