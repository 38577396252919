.registration-form {}
.registration-form__sub {
  text-align: center;
  margin: 16px 0 32px 0;
}
.registration-form__row {
  margin-bottom: 48px;
}
.registration-form__row_help {
  margin-bottom: 16px;
}
.registration-form__footer {
  margin-top: 64px;
}
.registration-form__login {
  margin-top: 16px;
  text-align: center;
}
