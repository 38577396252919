.user-data-block {}
.user-data-block__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.user-data-block__col {
  flex: 1;
  align-items: flex-start;
  margin-right: 48px;
}
.user-data-block__data {
  margin-bottom: 24px;
}
.user-data-block__data_row {
  margin-bottom: 0;
  margin-right: 48px;
}
.user-data-block__label {
  margin-bottom: 8px;
}
.user-data-block__value {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: 700;
}
.user-data-block__icon-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 24px;
}
.user-data-block__icon-row-icon {
  margin-right: 8px;
}
.user-data-block__icon-row-icon_next {
  margin-left: 16px;
}
.user-data-block__line {
  height: 1px;
  margin-bottom: 24px;
  border: none;
  background-color: #DFE0EB;
}
.user-data-block__row {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 24px;
}
.user-data-block__fire {
  height: 16px;
  margin-right: 8px;
  align-self: flex-end;
}
