@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes line {
  0% {
    left: -10%;
  }

  50% {
    left: 60%;
  }

  100% {
    left: -10%;
  }
}

.progress-linear-component {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.progress-linear-component__line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60%;
  animation: line 2s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
}

.progress-circle-component {
  position: relative;
  min-width: 32px;
  min-height: 32px;
}

.progress-circle-component__main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: rotate 1.5s linear infinite;
  transform-origin: center center;
  will-change: transform;
}

.progress-circle-component__circle {
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}
