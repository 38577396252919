.input-text-component {
  display: block;
  position: relative;
  height: 32px;
  line-height: 32px;
}

  .input-text-component input:-webkit-autofill + .input-text-component__label {
  top: -4px;
  font-size: 12px;
  }

.input-text-component__input:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px #fff;
}

.input-text-component_active {}

.input-text-component_active .input-text-component__label {
  top: -4px;
  font-size: 12px;
  color: #0648b8;
}

.input-text-component_active .input-text-component__line {
  width: 100%;
}

.input-text-component_fill {}

.input-text-component_fill .input-text-component__label {
  top: -4px;
  font-size: 12px;
}

.input-text-component_readonly {
  pointer-events: none;
}

.input-text-component_readonly .input-text-component__label {
  top: -4px;
  font-size: 12px;
}

.input-text-component_readonly .input-text-component__input {
  border-bottom: none;
}

.input-text-component_error {}

.input-text-component_error .input-text-component__line {
  background-color: #d50000;
}

.input-text-component_error .input-text-component__input {
  border-bottom-color: #d50000;
}

.input-text-component_error .input-text-component__label {
  color: #d50000;
}

.input-text-component_loading {
  pointer-events: none;
}

.input-text-component_loading .input-text-component__label {
  opacity: 0.6;
}

.input-text-component__input {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  border-bottom: #d8d8d8 1px solid;
  background-color: transparent;
  font: 14px Roboto, sans-serif;
  outline: none;
}

.input-text-component__label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.6);
  line-height: 1;
  transition: top 0.1s ease, font-size 0.1s ease;
  will-change: transform;
}

.input-text-component__line {
  position: absolute;
  width: 0;
  height: 2px;
  margin-top: -2px;
  left: 50%;
  bottom: -1px;
  transform: translateX(-50%);
  background-color: #0648b8;
  transition: width 0.2s ease;
}

.input-text-component__error {
  position: absolute;
  left: 0;
  right: 0;
  bottom: auto;
  margin-top: 4px;
  color: #d50000;
  line-height: 1;
  font-size: 12px;
}

.input-text-component__loader {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -1px;
  height: 1px;
}

.input-text-component__help {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 5px;
  color: #696969;
  font-size: 12px;
  line-height: 1.2;
}
