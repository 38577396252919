.additional-page {}
.additional-page__filter {
  margin: 32px 0 24px 0;
}
.additional-page__main {
  margin: 0 -16px -16px;
  overflow-x: auto;
}
.additional-page__options {
  width: 16px;
}
.additional-page__options-label {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.additional-page__options-label-icon {
  margin-right: 8px;
}
