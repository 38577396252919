.cabinet-layout {
  position: relative;
  width: 100%;
  height: inherit;
}
.cabinet-layout__aside {
  position: fixed;
  width: 250px;
  height: 100vh;
  background-color: #234CAC;
}
@media (max-width: 1000px) {
  .cabinet-layout__aside {
  width: 58px;
  }
    }
@media (max-width: 620px) {
  .cabinet-layout__aside {
  position: relative;
  width: 100vw;
  height: 58px;
  }
    }
.cabinet-layout__main {
  height: 100%;
  padding: 16px 16px 16px calc(250px + 16px);
  background-color: #F7F8FC;
  line-height: 32px;
}
@media (max-width: 1000px) {
  .cabinet-layout__main {
  padding-left: calc(58px + 16px);
  }
    }
@media (max-width: 620px) {
  .cabinet-layout__main {
  padding: 16px;
  }
    }
.cabinet-layout__header {
  margin-bottom: 32px;
}
@media (max-width: 620px) {
  .cabinet-layout__header {
  margin-bottom: 16px;
  }
    }
