.input-radio-component {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  user-select: none;
  cursor: pointer;
}
.input-radio-component__input {
  flex: 0 0 20px;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #d8d8d8;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.input-radio-component__input::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  right: 4px;
  bottom: 4px;
  background-color: #0648b8;
  border-radius: 50%;
  display: none;
    }
.input-radio-component__input:checked {
  box-shadow: inset 0 0 0 1px #0648b8;
    }
.input-radio-component__input:checked::after {
  display: block;
    }
.input-radio-component__label {
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1;
}
.input-radio-component_disabled {
  pointer-events: none;
  opacity: 0.5;
}

