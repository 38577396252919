.title {
  color: inherit;
  line-height: 1;
}
.title_size_h1 {
  font-size: 40px;
  font-weight: bold;
}
@media (max-width: 800px) {
  .title_size_h1 {
  font-size: 28px;
  }
    }
.title_size_h2 {
  font-size: 28px;
  font-weight: 700;
}
@media (max-width: 800px) {
  .title_size_h2 {
  font-size: 20px;
  }
    }
.title_size_h3 {
  font-size: 20px;
  font-weight: 500;
}
.title_size_h4 {
  font-size: 18px;
  font-weight: normal;
}
.title_size_h5 {
  color: #BDBFCE;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}
.title_size_h6 {
  font-size: 16px;
  font-weight: 500;
}
