.header-block {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
}
.header-block__tariff {
  flex: 0 1 60%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  list-style: none;
}
@media (max-width: 850px) {
  .header-block__tariff {
  flex: 0 0 auto;
  }
    }
@media (max-width: 620px) {
  .header-block__tariff {
  flex-flow: column;
  align-items: flex-start;
  }
    }
.header-block__tariff-item {
  margin-right: 32px;
  line-height: 1;
}
@media (max-width: 620px) {
  .header-block__tariff-item {
  display: flex;
  flex-flow: row-reverse nowrap;
  align-items: center;
  margin-bottom: 8px;
  }
    }
.header-block__tariff-label {
  margin-top: 8px;
  font-size: 12px;
  color: rgba(37, 39, 51, 0.4);
}
@media (max-width: 620px) {
  .header-block__tariff-label {
  margin: 0 8px 0 0;
  }
    }
.header-block__user {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: bold;
}
@media (max-width: 620px) {
  .header-block__user {
  display: none;
  }
    }
.header-block__icon {
  margin-left: 8px;
  font-size: 36px;
  color: #e4e4e4;
}
.header-block__switch {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: auto;
  padding-right: 32px;
  gap: 8px;
}
