.registration-step-phones-form {}
.registration-step-phones-form__sub {
  max-width: 340px;
  margin: 64px 0 16px 0;
}
.registration-step-phones-form__row {
  margin-bottom: 16px;
}
.registration-step-phones-form__label {
  color: #000;
  font-weight: 500;
}
.registration-step-phones-form__footer {
  margin-top: 64px;
}
