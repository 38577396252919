.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  outline: none;
  line-height: 1;
  text-decoration: none;
}
.button_theme_default {
  border-radius: 4px;
  transition: background 0.1s ease;
  text-decoration: none;
  text-align: center;
}
.button_theme_default.button_color_orange {
  background-color: #DD5D3D;
  color: #fff;
}
.button_theme_default.button_color_orange:hover {
  background-color: #ca5235;
      }
.button_theme_default.button_color_white {
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
.button_theme_default.button_size_small {
  height: 32px;
  padding: 0 16px;
  font-size: 14px;
}
.button_theme_default.button_size_middle {
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 500;
}
.button_theme_plain {
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
}
.button_theme_plain.button_active {
  background-color: #EAEAEB;
  color: rgba(0, 0, 0, 0.4);
}
.button_theme_plain.button_size_small {
  padding: 6px 8px;
  font-size: 12px;
  line-height: 14px;
}
.button_theme_nav {
  display: inline-block;
  color: #000;
  font-size: 16px;
  text-decoration: none;
}
.button_theme_nav.button_size_small {
  font-size: 14px;
}
.button_theme_nav.button_active {
  color: #1A4AB2;
}
.button_theme_link {
  display: inline-block;
  color: #0648b8;
  text-decoration: none;
  font-size: inherit;
  font-weight: inherit;
  background-color: transparent;
}
.button_theme_link:hover {
  text-decoration: underline;
    }
.button_theme_link.button_color_white {
  color: #fff;
}
.button_theme_border {
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  color: #000;
  background-color: transparent;
  transition: all 0.2s ease;
  text-align: center;
}
.button_theme_border:hover {
  background-color: #fff;
    }
.button_theme_border.button_color_white {
  color: #fff;
}
.button_theme_border.button_color_white:hover {
  background-color: transparent;
      }
.button_theme_border.button_color_blue {
  color: #234CAC;
  border: 1px solid #234CAC;
}
.button_theme_border.button_color_blue:hover {
  color: #fff;
  background-color: #234CAC;
      }
.button_theme_border.button_size_small {
  padding: 11px 16px;
  font-size: 14px;
}
.button_theme_border.button_size_middle {
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 500;
}
.button_block {
  display: flex;
  width: 100%;
}

