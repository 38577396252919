@keyframes lamp-rotate {
  0% {
    transform: rotate(10deg);
  }

  30% {
    transform: rotate(6deg);
  }

  50% {
    transform: rotate(0deg);
  }

  70% {
    transform: rotate(-6deg);
  }

  100% {
    transform: rotate(-10deg);
  }
}

@keyframes lamp-blink {
  0% {
    visibility: visible;
  }

  10% {
    visibility: hidden;
  }

  50% {
    visibility: visible;
  }

  60% {
    visibility: hidden;
  }

  80% {
    visibility: visible;
  }
}

.error-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 32px auto 32px;
}

.error-layout__header {
  flex: 0 0 60px;
  margin: 0 64px;
}

@media (max-width: 520px) {

.error-layout__header {
  margin: 0 32px;
  }
    }

.error-layout__main {
  flex: 1 1 auto;
  display: flex;
  margin: 0 64px;
}

@media (max-width: 1000px) {

.error-layout__main {
  margin-right: 0;
  }
    }

@media (max-width: 520px) {

.error-layout__main {
  margin: 0 32px;
  }
    }

.error-layout__center {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1000px) {

.error-layout__center {
  width: 100%;
  }
    }

.error-layout__content {
  flex: 1 1 auto;
  margin-right: 64px;
}

@media (max-width: 520px) {

.error-layout__content {
  width: 100%;
  }
    }

.error-layout__code {
  color: #989898;
  font-size: 72px;
  font-weight: 100;
  line-height: 1;
}

.error-layout__title {
  margin: 0 0 16px 0;
  font-size: 64px;
  line-height: 1;
}

.error-layout__title::after {
  content: "";
  display: block;
  width: 180px;
  margin-top: 16px;
  height: 4px;
  background-color: #0e50c8;
    }

.error-layout__text {
  max-width: 450px;
  margin-top: 64px;
  font-size: 18px;
  font-weight: 300;
  color: #909090;
  line-height: 24px;
}

.error-layout__controls {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 64px;
  list-style: none;
}

.error-layout__control {
  width: 180px;
  margin-right: 16px;
}

.error-layout__picture {
  position: relative;
}

@media (max-width: 520px) {

.error-layout__picture {
  display: none;
  }
    }

.error-layout__picture_type_code::before {
  content: "";
  position: absolute;
  top: 0;
  left: 40%;
  display: block;
  width: 40%;
  height: 100%;
  background-image: url("/assets/images/not-found-lamp.png");
  background-position: center  3px;
  background-size: contain;
  background-repeat: no-repeat;
  transform-origin: top;
  animation: lamp-rotate 1s linear infinite alternate;
  will-change: transform;
      }

@media (max-width: 1000px) {

.error-layout__picture_type_code {
  width: 300px;
  height: 100%;
  background-image: url("/assets/images/not-found-bg.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  }

        .error-layout__picture_type_code::before {
  display: none;
        }
      }

.error-layout__picture_type_technical::before {
  content: "";
  position: absolute;
  top: 33%;
  left: 38.5%;
  display: block;
  width: 40%;
  bottom: 0;
  background-image: url("/assets/images/technical-error-light.png");
  background-position: center  3px;
  background-size: contain;
  background-repeat: no-repeat;
  animation: lamp-blink 1s steps(5, start) infinite alternate;
  will-change: transform;
      }

@media (max-width: 1000px) {

.error-layout__picture_type_technical {
  width: 300px;
  height: 100%;
  background-image: url("/assets/images/technical-error-bg.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  }

        .error-layout__picture_type_technical::before {
  display: none;
        }
      }

.error-layout__img {
  max-width: 100%;
}

@media (max-width: 1000px) {

.error-layout__img {
  display: none;
  }
    }
